import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './components/header/header.component';
import { NgxMaskModule } from 'ngx-mask';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { MessagesService } from "./services/messages.service";
import { AuthService } from "./services/auth.service";
import { RequestsInterceptor } from "./services/requests.interceptor"

@NgModule({
	declarations: [
		AppComponent,
		HeaderComponent,
	],
	imports: [
		BrowserModule,
		FormsModule,
		HttpClientModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		NgxMaskModule.forRoot(),
		BsDatepickerModule.forRoot(),
	],
	providers: [
		MessagesService,
		AuthService,
		RequestsInterceptor,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: RequestsInterceptor,
			multi: true
		},
	],
	bootstrap: [ AppComponent ]
})
export class AppModule {
}
