import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { AuthService, IGetCodeResp } from "./services/auth.service";
import { Subscription } from "rxjs";
import { MessagesService, IChat } from "./services/messages.service";

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: [ './app.component.scss' ]
})
export class AppComponent implements OnInit {

	getCodeSub: Subscription = new Subscription;
	authSub: Subscription = new Subscription;
	getChatsListSub: Subscription = new Subscription;

	state: string = STATE.AUTH;
	sessionId: string = '';
	formData = {
		phone: '',
		code: '',
		group: '',
		dateFrom: new Date(),
		dateTo: new Date()
	};
	chatsList: IChat[] = [];
	chatSelected: IChat = {
		id: '',
		title: ''
	};
	date = Math.floor(Date.now() / 1000);

	constructor(
		private authService: AuthService,
		private messagesService: MessagesService,
	) {
	}

	ngOnInit() {
		// @ts-ignore
		if (sessionStorage.getItem('sessionId') && (this.date - sessionStorage.getItem('sessionAuthTime') < 600)) {
			this.state = STATE.CHATSLIST;
			this.sessionId = sessionStorage.getItem('sessionId') || '';
			this.getChatsList();
		} else {
			sessionStorage.clear();
		}
	}

	getCode() {
		this.getCodeSub = this.authService.getCode(this.formData.phone).subscribe((res) => {
			console.log('res', res);//TODO: Delete
			this.state = STATE.CODE;
			this.sessionId = res.session_id;
			sessionStorage.setItem('sessionId', res.session_id);

			// @ts-ignore
			sessionStorage.setItem('sessionAuthTime', this.date);
		});
	}

	auth() {
		if (this.formData.code.length == 5) this.authSub = this.authService.auth(+this.formData.code, this.sessionId).subscribe((res) => {
			this.getChatsList();
		});
	}

	getChatsList() {
		this.getChatsListSub = this.messagesService.getChatsList().subscribe((chats) => {
			this.state = STATE.CHATSLIST;
			this.chatsList = chats;
		});
	}

	selectChat(chat: IChat) {
		this.chatSelected = chat;
		this.state = STATE.CHATMANAGE;

		// @ts-ignore
		this.formData.group = chat;//todo remove
	}

	rangePick(range: Date[]) {
		// @ts-ignore
		this.formData.dateFrom = new Date(range[0]).setHours(0,0,0,0);
		// @ts-ignore
		this.formData.dateTo = new Date(range[1]).setHours(23,59,59,999);
	}

	removeMessage() {
		const data = {
			chat_id: this.chatSelected.id,
			after: (+this.formData.dateFrom)/1000,
			before: (+this.formData.dateTo)/1000
		}
		this.messagesService.removeMessage(data).subscribe(() => {
			alert('Операция успешна!')
		},err => {
			alert('Операция неуспешна!')
		})
	}

	backToState(state: string) {
		this.state = state;
	}

	ngOnDestroy() {
		if (this.getCodeSub) this.getCodeSub.unsubscribe();
		if (this.authSub) this.authSub.unsubscribe();
		if (this.getChatsListSub) this.getChatsListSub.unsubscribe();
	}

}

enum STATE {
	AUTH = 'AUTH',
	CODE = 'CODE',
	CHATSLIST = 'CHATSLIST',
	CHATMANAGE = 'CHATMANAGE'
}
