import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "../../environments/environment";

@Injectable({
	providedIn: 'root'
})
export class MessagesService {

	constructor(
		private http: HttpClient,
	) {
	}

    getChatsList(): Observable<IChat[]> {
	  return this.http.get<IChat[]>(`${environment.baseUrl}Messages/Chats`);
    }

    removeMessage(data: IRemoveMessageBody): Observable<boolean> {
	  return this.http.post<boolean>(`${environment.baseUrl}Messages/Remove`, data);
    }
}

export interface IChat {
  "id": string,
  "title": string
}

interface IRemoveMessageBody {
	"chat_id": string,
	"after": number,
	"before": number
}
