import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
  ) { }

  getCode(phone: string): Observable<IGetCodeResp> {
    return this.http.post<IGetCodeResp>(`${environment.baseUrl}Auth/RequestCode`, {phone: `+7${phone}`});
  }

  auth(code: number, sessionId: string): Observable<boolean> {
    return this.http.post<boolean>(`${environment.baseUrl}Auth`, {session_id: sessionId, code: code});
  }

}

export interface IGetCodeResp {
  session_id: string,
}
