<!--<router-outlet></router-outlet>-->

<app-header></app-header>

<div class="content">

	<section class="auth">
		<ng-container *ngIf="state == 'AUTH' || state == 'CODE'">
			<div class="form-group">
				<label>Номер телефона</label>
				<input type="text" prefix="+7" mask="0000000000" class="form-control" [(ngModel)]="formData.phone">
			</div>

			<button *ngIf="state == 'AUTH'" type="submit" class="btn btn-primary full-width" (click)="getCode()" [disabled]="formData.phone?.length !== 10">ПОЛУЧИТЬ КОД</button>
		</ng-container>

		<ng-container *ngIf="state == 'CODE'">
			<div class="form-group">
				<label>Код</label>
				<input type="text" placeholder="Введите код" class="form-control" [(ngModel)]="formData.code" (ngModelChange)="auth()">
			</div>

			<div class="resend-code">
				<span (click)="getCode()">Отправить код повторно</span>
			</div>
		</ng-container>
	</section>

	<ng-container *ngIf="state == 'CHATSLIST'">
		<span class="back-btn" (click)="backToState('AUTH')">Назад</span>
		<section class="chats-list">
			<div class="title">Список чатов</div>
			<ul>
				<li *ngFor="let chat of chatsList" (click)="selectChat(chat)">{{chat.title}}</li>
			</ul>

			<div *ngIf="!chatsList.length" class="no-chats">Список пуст</div>
		</section>
	</ng-container>

	<ng-container *ngIf="state == 'CHATMANAGE'">
		<span class="back-btn" (click)="backToState('CHATSLIST')">Назад</span>
		<section class="group-management">

			<label>Группа</label>
			<div class="input-group">
				<select class="custom-select" [value]="formData.group" disabled>
					<option [value]="chat.id" *ngFor="let chat of chatsList" [selected]="chatSelected.id == chat.id">{{chat.title}}</option>
				</select>
			</div>

			<div class="range-picker">
				<label>Период</label>
				<input type="text"
					   placeholder="Выбрать даты"
					   class="form-control"
					   bsDaterangepicker
					   (bsValueChange)="rangePick($event)"
					   [bsConfig]="{ isAnimated: true, rangeInputFormat : 'DD.MM.YYYY', dateInputFormat: 'DD.MM.YYYY' }">
			</div>

			<button type="submit" class="btn btn-primary" (click)="removeMessage()" [disabled]="!formData.dateFrom && !formData.dateTo && !formData ">ЗАПУСТИТЬ</button>
		</section>
	</ng-container>

</div>
