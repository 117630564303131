import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor, HttpResponse
} from '@angular/common/http';
import { filter, mergeMap } from "rxjs/operators";
import { Observable, of, throwError } from 'rxjs';

@Injectable()
export class RequestsInterceptor implements HttpInterceptor {

	constructor(
	) {
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		// @ts-ignore
		return next.handle(this.addSessionId(request)).pipe(
			filter((e) => e instanceof HttpResponse),
			// @ts-ignore
			mergeMap((event) => {
				// @ts-ignore
				if (event.body.status_code >= 400 && event.body.status_code != 401 && event.body.status_code != 403) {
					// @ts-ignore
					return throwError(event.body);
				} else {
					// @ts-ignore
					return of(new HttpResponse({ body: event.body.data }));
				}
			})
		);
	}

	addSessionId(req: HttpRequest<any>) {
		if(req.url.includes('Messages')) {
			return req.clone({
				setHeaders: {
					'Content-Type': 'application/json',
					// @ts-ignore
					'Session-Id': sessionStorage.getItem('sessionId'),
					// 'Session-Id': '9c5fc285-a025-4e92-9810-86839de3034b',
				}
			});
		} else {
			return req;
		}
	}
}
